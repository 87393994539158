import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import '@/assets/common.css';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { ContextProvider } from '@/context';
import { App } from './App';
import { wagmiConfig } from './wagmiConfig';
const queryClient = new QueryClient();
const root = createRoot(document.getElementById('root'));
root.render(_jsx(_Fragment, { children: _jsx(WagmiProvider, { config: wagmiConfig, children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(ContextProvider, { children: _jsx(App, {}) }) }) }) }));
