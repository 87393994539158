// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main {
  width: 600px;
  min-width: 600px;
  height: 100%;
}

@media (max-width: 767px) {
  main {
    width: 304px;
    min-width: 304px;
    height: 100%;
  }
}

#container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#container::-webkit-scrollbar {
  display: none;
}

.typo {
  font-feature-settings: 'palt' 1;
  text-justify: inter-word;
  overflow-wrap: break-word;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -webkit-appearance: textfield;
          appearance: textfield;
  -moz-appearance: textfield;
}

input {
  overflow: clip;
}

.focus-outline:focus {
  outline: 1px var(--primary-color) dashed;
}

.replace-btn {
  position: relative;
  opacity: 0.2s var(--ease-out-quart);
}
.replace-btn::after {
  opacity: 0;
  content: '';
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  left: 2px;
  top: 2px;
  background-color: var(--replace-color);
  border-radius: 50%;
  z-index: -1;
}
.replace-btn:hover::after {
  opacity: 1;
}

.bridge-info {
  background-color: var(--info-color);
}

.arrow {
  font-size: 0.75em;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/domain/main/main.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE;IACE,YAAY;IACZ,gBAAgB;IAChB,YAAY;EACd;AACF;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;AACvB;AACA;EACE,aAAa;AACf;;AAEA;EACE,+BAA+B;EAC/B,wBAAwB;EACxB,yBAAyB;AAC3B;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,6BAAqB;UAArB,qBAAqB;EACrB,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;EAClB,mCAAmC;AACrC;AACA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,uBAAuB;EACvB,wBAAwB;EACxB,SAAS;EACT,QAAQ;EACR,sCAAsC;EACtC,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,UAAU;AACZ;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":["main {\n  width: 600px;\n  min-width: 600px;\n  height: 100%;\n}\n\n@media (max-width: 767px) {\n  main {\n    width: 304px;\n    min-width: 304px;\n    height: 100%;\n  }\n}\n\n#container {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n#container::-webkit-scrollbar {\n  display: none;\n}\n\n.typo {\n  font-feature-settings: 'palt' 1;\n  text-justify: inter-word;\n  overflow-wrap: break-word;\n}\n\ninput[type='number']::-webkit-outer-spin-button,\ninput[type='number']::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\ninput[type='number'] {\n  appearance: textfield;\n  -moz-appearance: textfield;\n}\n\ninput {\n  overflow: clip;\n}\n\n.focus-outline:focus {\n  outline: 1px var(--primary-color) dashed;\n}\n\n.replace-btn {\n  position: relative;\n  opacity: 0.2s var(--ease-out-quart);\n}\n.replace-btn::after {\n  opacity: 0;\n  content: '';\n  position: absolute;\n  width: calc(100% - 4px);\n  height: calc(100% - 4px);\n  left: 2px;\n  top: 2px;\n  background-color: var(--replace-color);\n  border-radius: 50%;\n  z-index: -1;\n}\n.replace-btn:hover::after {\n  opacity: 1;\n}\n\n.bridge-info {\n  background-color: var(--info-color);\n}\n\n.arrow {\n  font-size: 0.75em;\n  vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
