import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import useNetworkManager from '@/hooks/useNetworkManager';
const NetworkManagerContext = createContext(null);
export const NetworkManagerProvider = ({ children }) => {
    const { networkManager, loading } = useNetworkManager();
    if (!loading && networkManager) {
        return (_jsx(_Fragment, { children: _jsx(NetworkManagerContext.Provider, { value: networkManager, children: children }) }));
    }
};
export const useNetworkManagerContext = () => {
    const context = useContext(NetworkManagerContext);
    if (!context) {
        throw new Error('useNetworkManagerContext must be used within a NetworkManagerProvider');
    }
    return context;
};
