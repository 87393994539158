import { memo, useEffect } from 'react';
import ReactDOM from 'react-dom';
const Teleport = memo(({ children }) => {
    const container = document.createElement('div');
    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container);
        };
    }, [container]);
    return ReactDOM.createPortal(children, container);
});
export default Teleport;
