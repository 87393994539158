import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import discordBlack from '@/assets/sns/discord_black.svg';
import discord from '@/assets/sns/discord_gray.svg';
import docsBlack from '@/assets/sns/docs_black.svg';
import docs from '@/assets/sns/docs_gray.svg';
import githubBlack from '@/assets/sns/github_black.svg';
import github from '@/assets/sns/github_gray.svg';
import mediumBlack from '@/assets/sns/medium_black.svg';
import medium from '@/assets/sns/medium_gray.svg';
import xBlack from '@/assets/sns/x_black.svg';
import x from '@/assets/sns/x_gray.svg';
import { useIsDarkModeContext } from '@/context';
const SnsNav = memo(() => {
    const { isDarkMode } = useIsDarkModeContext();
    return (_jsx("nav", { role: "navigation", children: _jsxs("div", { className: "flex relative items-center justify-around rounded-full px-3 py-2", children: [_jsx("a", { href: "https://github.com/Pheasant-Network", target: "_blank", rel: "noreferrer", "aria-label": "Github", children: _jsx("img", { src: isDarkMode ? githubBlack : github, className: "item w-6 mx-2", alt: "Github", width: "24", height: "24" }) }), _jsx("a", { href: "https://x.com/PheasantNetwork", target: "_blank", rel: "noreferrer", "aria-label": "X", children: _jsx("img", { src: isDarkMode ? xBlack : x, className: "item w-6 mx-2", alt: "X", width: "20", height: "18" }) }), _jsx("a", { href: "https://discord.gg/pRYSEUVERT", target: "_blank", rel: "noreferrer", "aria-label": "Discord", children: _jsx("img", { src: isDarkMode ? discordBlack : discord, className: "item w-6 mx-2", alt: "Discord", width: "24", height: "18" }) }), _jsx("a", { href: "https://blog.pheasant.network/", target: "_blank", rel: "noreferrer", "aria-label": "Medium", children: _jsx("img", { src: isDarkMode ? mediumBlack : medium, className: "item w-6 mx-2", alt: "Medium", width: "24", height: "14" }) }), _jsx("a", { href: "https://docs.pheasant.network", target: "_blank", rel: "noreferrer", "aria-label": "Docs", children: _jsx("img", { src: isDarkMode ? docsBlack : docs, className: "item w-6 mx-2", alt: "Docs", width: "24", height: "14" }) })] }) }));
});
export default SnsNav;
