import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { getImage } from '@/utils';
import Modal from '@/components/Modal';
import Teleport from '@/components/Teleport';
import { useAccount, useConnect } from 'wagmi';
import { WALLETS } from '@/constants';
const WalletModal = memo(({ isWalletModalOpen, setIsWalletModalOpen }) => {
    const { connect, connectors, error: connectError, isLoading: isConnecting, pendingConnector, } = useConnect({
        onError(error) {
            console.log('Error', error);
        },
    });
    const { isConnected } = useAccount();
    // TODO for walletconnect
    //const { setWalletImagePath } = useWalletImagePath()
    return (_jsx(Teleport, { children: _jsx(Modal, { title: "Connect Wallet", onClose: () => {
                setIsWalletModalOpen(false);
            }, withButton: true, isOpen: isWalletModalOpen && !isConnected, isMinHeight: true, isWalletConnect: true, children: _jsxs("div", { children: [connectors.map((connector) => (_jsx("div", { children: _jsx("button", { disabled: !connector.ready, onClick: () => {
                                try {
                                    connect({ connector });
                                    // setWalletImagePath(WALLETS.find((wallet) => wallet.id === connector.name).img)
                                    // localStorage.setItem(
                                    //   'walletConnectImagePath',
                                    //   JSON.stringify(
                                    //     WALLETS.find((wallet) => wallet.id === connector.name)
                                    //       .img,
                                    //   ),
                                    // )
                                }
                                catch (error) {
                                    console.log(error);
                                }
                            }, className: "button button-white p-2 w-full bg-white dark:bg-custom-lighter dark:text-custom-white rounded-xl text-clip md:px-2 mb-2", type: "button", "aria-label": `Connect with ${connector.name}`, "aria-disabled": !connector.ready, children: _jsxs("span", { children: [_jsx("img", { className: "h-6 inline mr-1", src: getImage(WALLETS.find((wallet) => wallet.id === connector.name)
                                            .img), alt: "Wallet Icon", style: {
                                            width: '1.5rem',
                                            height: '1.625rem',
                                        } }), connector.name, !connector.ready && ' (not detected)'] }) }, connector.name) }, connector.name))), connectError && _jsx("div", { children: connectError.message })] }) }) }));
});
export default WalletModal;
