import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useContentful } from '@/hooks/useContentful';
import { useIsVisibleNewsBanner } from '@/context';
const NewsBar = styled.div `
  width: 100%;
  height: 3.25rem;
  background: linear-gradient(89.9deg, #00c9a2 -0.05%, #00c942 100%),
    linear-gradient(89.81deg, #00edbf -0.1%, #00c641 108.33%);
  position: relative;
`;
const CloseButton = styled.button `
  position: absolute;
  right: 10px;
  color: rgba(35, 36, 36, 1);
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
const NewsBanner = memo(() => {
    const [isVisible, setIsVisible] = useState(true);
    const { getAllnews, news } = useContentful();
    const { setIsVisibleNewsBanner } = useIsVisibleNewsBanner();
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    useEffect(() => {
        getAllnews();
    }, []);
    useEffect(() => {
        setIsVisibleNewsBanner(news && news.length > 0 && isVisible);
    }, [isVisible, news]);
    return (_jsx(_Fragment, { children: news && news.length > 0 && isVisible && (_jsx(NewsBar, { "aria-label": "News", children: _jsxs("div", { className: "flex h-full justify-center items-center px-4", children: [news[news.length - 1], _jsx(CloseButton, { onClick: toggleVisibility, children: "X" })] }) })) }));
});
export default NewsBanner;
