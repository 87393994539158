import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import { getImage } from '@/utils';
import Modal from '@/components/Modal';
import Teleport from '@/components/Teleport';
import { useAccount, useConnect } from 'wagmi';
import { WALLETS } from '@/constants';
import WalletNotDetectedModal from './WalletNotDetectedModal';
const WalletModal = memo(({ isWalletModalOpen, setIsWalletModalOpen }) => {
    const { connect, connectors, error: connectError } = useConnect();
    const { isConnected } = useAccount();
    // TODO for walletconnect
    //const { setWalletImagePath } = useWalletImagePath()
    const [isNotDetected, setIsNotDetected] = useState(false);
    const [walletName, setWalletName] = useState('');
    useEffect(() => {
        if (connectError !== null) {
            setIsWalletModalOpen(false);
            setIsNotDetected(true);
        }
    }, [connectError]);
    return (_jsxs(_Fragment, { children: [_jsx(Teleport, { children: _jsx(Modal, { title: "Connect Wallet", onClose: () => {
                        setIsWalletModalOpen(false);
                    }, withButton: true, isOpen: isWalletModalOpen && !isConnected, isMinHeight: true, isWalletConnect: true, isBigScreen: true, children: _jsx("div", { className: "grid grid-cols-3 mobile:grid-cols-2 gap-4", children: connectors
                            .filter((connector) => WALLETS.find((wallet) => wallet.id === connector.id))
                            .sort((a, b) => {
                            const indexA = WALLETS.findIndex((wallet) => wallet.id === a.id);
                            const indexB = WALLETS.findIndex((wallet) => wallet.id === b.id);
                            return indexA - indexB;
                        })
                            .map((connector) => (_jsx("div", { className: "flex items-center justify-center", children: _jsxs("button", { onClick: () => {
                                    try {
                                        connect({ connector });
                                        setWalletName(connector.name);
                                        // setWalletImagePath(WALLETS.find((wallet) => wallet.id === connector.name).img)
                                        // localStorage.setItem(
                                        //   'walletConnectImagePath',
                                        //   JSON.stringify(
                                        //     WALLETS.find((wallet) => wallet.id === connector.name)
                                        //       .img,
                                        //   ),
                                        // )
                                    }
                                    catch (error) {
                                        console.log(error);
                                    }
                                }, className: "p-2 w-full h-full bg-neutral-0 hover:bg-secondary-10 dark:bg-secondary-900 dark:hover:bg-secondary-800 dark:text-neutral-0 rounded-8 text-clip flex flex-col items-center justify-center", type: "button", "aria-label": `Connect with ${connector.name}`, "aria-disabled": !connector.ready, children: [_jsx("img", { className: "mb-2", src: getImage('wallet/' +
                                            WALLETS.find((wallet) => wallet.name === connector.name).img), alt: "Wallet Icon", style: {
                                            width: '30px',
                                            height: '30px',
                                        } }), _jsx("span", { className: "text-center text-label_l mobile:text-label_m font-medium", children: connector.name })] }, connector.name) }, connector.name))) }) }) }), isNotDetected && (_jsx(WalletNotDetectedModal, { isOpen: isNotDetected, setIsOpen: setIsNotDetected, setIsWalletModalOpen: setIsWalletModalOpen, walletName: walletName }))] }));
});
export default WalletModal;
