import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import discordDark from '@/assets/new-icon/sns/discord-dark.svg';
import discord from '@/assets/new-icon/sns/discord.svg';
import blogDark from '@/assets/new-icon/sns/blog-dark.svg';
import blog from '@/assets/new-icon/sns/blog.svg';
import githubDark from '@/assets/new-icon/sns/github-dark.svg';
import github from '@/assets/new-icon/sns/github.svg';
import mediumDark from '@/assets/new-icon/sns/medium-dark.svg';
import medium from '@/assets/new-icon/sns/medium.svg';
import xDark from '@/assets/new-icon/sns/x-dark.svg';
import x from '@/assets/new-icon/sns/x.svg';
import telegramDark from '@/assets/new-icon/sns/telegram-dark.svg';
import telegram from '@/assets/new-icon/sns/telegram.svg';
import { useIsDarkModeContext } from '@/context';
const SnsNav = memo(() => {
    const { isDarkMode } = useIsDarkModeContext();
    return (_jsx("nav", { role: "navigation", children: _jsxs("div", { className: "flex absolute left-8 bg-neutral-800 dark:bg-secondary-800 items-center justify-center rounded-100 px-3 py-2 flex-col bottom-1/2 translate-y-1/2 mobile:hidden", children: [_jsx("a", { href: "https://github.com/Pheasant-Network", target: "_blank", 
                    // className="transition hover:-translate-y-1 flex tablet:my-1"
                    className: "transition hover:-translate-y-1 flex my-1", rel: "noreferrer", "aria-label": "Github", children: _jsx("img", { src: isDarkMode ? githubDark : github, 
                        // className="w-6 mx-2 tablet:mx-0 tablet:my-2"
                        className: "w-6 mx-0 my-2", alt: "Github", width: "24", height: "24" }) }), _jsx("a", { href: "https://x.com/PheasantNetwork", target: "_blank", className: "transition hover:-translate-y-1 flex my-1", rel: "noreferrer", "aria-label": "X", children: _jsx("img", { src: isDarkMode ? xDark : x, className: "w-6 mx-0 my-2", alt: "X", width: "20", height: "18" }) }), _jsx("a", { href: "https://discord.gg/pRYSEUVERT", target: "_blank", className: "transition hover:-translate-y-1 flex my-1", rel: "noreferrer", "aria-label": "Discord", children: _jsx("img", { src: isDarkMode ? discordDark : discord, className: "w-6 mx-0 my-2", alt: "Discord", width: "24", height: "18" }) }), _jsx("a", { href: "https://t.me/pheasant_network", target: "_blank", className: "transition hover:-translate-y-1 flex my-1", rel: "noreferrer", "aria-label": "Telegram", children: _jsx("img", { src: isDarkMode ? telegramDark : telegram, className: "w-6 mx-0 my-2", alt: "Telegram", width: "24", height: "14" }) }), _jsx("a", { href: "https://home.pheasant.network/library", target: "_blank", className: "transition hover:-translate-y-1 flex my-1", rel: "noreferrer", "aria-label": "Medium", children: _jsx("img", { src: isDarkMode ? mediumDark : medium, className: "w-6 mx-0 my-2", alt: "Medium", width: "24", height: "14" }) }), _jsx("a", { href: "https://docs.pheasant.network", target: "_blank", className: "transition hover:-translate-y-1 flex my-1", rel: "noreferrer", "aria-label": "Docs", children: _jsx("img", { src: isDarkMode ? blogDark : blog, className: "w-6 mx-0 my-2", alt: "Docs", width: "24", height: "14" }) })] }) }));
});
export default SnsNav;
