import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from 'react-transition-group';
import close from '@/assets/icon/close-wh.svg';
const Modal = ({ title, children, onClose, withButton, isAlert = false, className = '', isOpen, isMinHeight = false, isWalletConnect = false, isDesktopProcessing = false, }) => {
    const transitionStyles = {
        entering: { opacity: 0 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    };
    return (_jsx(Transition, { in: isOpen, timeout: 300, appear: true, unmountOnExit: true, children: (state) => (_jsxs("div", { style: {
                ...transitionStyles[state],
                transition: 'opacity 300ms ease-in-out',
            }, className: `font-display flex justify-center items-center fixed top-0 left-0 w-full h-full z-50 ${className}`, children: [_jsx("button", { onClick: onClose, className: "cursor-pointer fixed w-full h-full bg-dark dark:bg-ground opacity-20 z-40 top-0 left-0" }), _jsxs("div", { className: `window fixed z-50 bg-ground dark:bg-custom-light pt-12 pb-8 px-8 rounded-2xl flex flex-col ${isAlert ? 'border-8 border-error' : ''}  ${isMinHeight ? 'min-h-[300px]' : ''}`, style: {
                        width: isDesktopProcessing ? '37rem' : '24rem',
                    }, children: [_jsx("h2", { className: `title whitespace-pb-8 nowrap rounded-full text-center ${isWalletConnect ? 'text-lg' : 'text-xl'} py-2 px-4 absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 ${isAlert
                                ? 'border-4 border-error bg-error before:bg-error dark:text-custom-white'
                                : 'border-ground dark:border-primary border-4 bg-primary dark:bg-custom-dark dark:text-custom-white before:primary'}`, children: title }), withButton && (_jsx("button", { onClick: onClose, "aria-label": "close modal", className: "flex justify-center items-center absolute -top-4 -right-4 w-10 h-10 bg-dark dark:bg-custom-dark rounded-full z-20 hover:bg-[#464849] dark:hover:bg-custom-lighter md:-right-3", children: _jsx("img", { src: close, alt: "Close Button", width: "16", height: "16", className: "w-4 h-4" }) })), children] })] })) }));
};
export default Modal;
