import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import search from '@/assets/icon/search.svg';
import searchDark from '@/assets/icon/search-dark.svg';
import { useIsDarkModeContext, useSelectedNetworkContext, useTradeTokenTypeIndexContext, } from '@/context';
import { formatNumberWithFourDecimalPlaces, getImage, getSelectableNetwork, } from '@/utils';
import { supportChains, supportUsdcChains } from '@/wagmiConfig';
import { getL1NetworkChainIds, getNetworkInfoByChainId, Token, } from '@pheasant-network/pheasant-sdk';
import { formatUnits } from 'viem';
const NetworkModalContent = ({ setIsOpenNetworkModal, networkFlag, selectFromNetwork, selectedToNetworkHandler, setIsFocusOut, balances, }) => {
    const isMainnet = process.env.REACT_APP_IS_MAINNET === 'true';
    const { isDarkMode } = useIsDarkModeContext();
    const { tradeTokenTypeIndex } = useTradeTokenTypeIndexContext();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredChains, setFilteredChains] = useState(supportChains);
    const filterChain = (chain) => {
        if (selectedFromNetwork.id === 0 && selectedToNetwork.id === 0)
            return true;
        const selectedNetwork = networkFlag === 'to' ? selectedFromNetwork : selectedToNetwork;
        if (selectedNetwork.id === 0)
            return;
        const selectableNetwork = getSelectableNetwork(chain.id);
        if (tradeTokenTypeIndex === Token.USDC && selectableNetwork.isUsdc) {
            return true;
        }
        const pairNetworkId = getNetworkInfoByChainId(selectedNetwork.networkID).pairNetworkId;
        const selectablePairNetworkId = getNetworkInfoByChainId(chain.id).pairNetworkId;
        if (isMainnet) {
            return true;
        }
        if (selectedNetwork.isL2) {
            return (selectableNetwork.isL2 || selectableNetwork.networkID === pairNetworkId);
        }
        else if (getL1NetworkChainIds().includes(selectedNetwork.networkID)) {
            return (!getL1NetworkChainIds().includes(selectableNetwork.networkID) &&
                selectedNetwork.networkID === selectablePairNetworkId);
        }
        else {
            return true;
        }
    };
    const displayChainName = (chain) => {
        if (chain.id === 59144)
            return 'Linea';
        return getSelectableNetwork(chain.id).label;
    };
    const onClick = (chain) => {
        if (networkFlag === 'from' &&
            (chain.id === selectedToNetwork?.networkID || !filterChain(chain))) {
            setSelectedToNetwork(getSelectableNetwork(0));
            setIsOpenNetworkModal(false);
        }
        if (networkFlag === 'from') {
            const selectableFromNetwork = getSelectableNetwork(chain.id);
            setSelectedFromNetwork(selectableFromNetwork);
            selectFromNetwork(selectableFromNetwork);
        }
        else if (networkFlag === 'to') {
            const selectableToNetwork = getSelectableNetwork(chain.id);
            setSelectedToNetwork(selectableToNetwork);
            selectedToNetworkHandler();
        }
        setIsFocusOut(true);
        setIsOpenNetworkModal(false);
    };
    const { selectedFromNetwork, setSelectedFromNetwork, selectedToNetwork, setSelectedToNetwork, } = useSelectedNetworkContext();
    // filtered selectable networks when dependencies changed
    useEffect(() => {
        if (supportChains.length > 0) {
            let filtered;
            if (tradeTokenTypeIndex === Token.USDC) {
                filtered =
                    networkFlag === 'to'
                        ? supportUsdcChains
                            .filter((chain) => chain.id !== selectedFromNetwork.networkID)
                            .filter((chain) => filterChain(chain))
                            .filter((chain) => chain.name.toLowerCase().includes(searchQuery.toLowerCase()))
                        : supportUsdcChains.filter((chain) => chain.name.toLowerCase().includes(searchQuery.toLowerCase()));
            }
            else {
                filtered =
                    networkFlag === 'to'
                        ? supportChains
                            .filter((chain) => chain.id !== selectedFromNetwork.networkID)
                            .filter((chain) => filterChain(chain))
                            .filter((chain) => chain.name.toLowerCase().includes(searchQuery.toLowerCase()))
                        : supportChains.filter((chain) => chain.name.toLowerCase().includes(searchQuery.toLowerCase()));
            }
            setFilteredChains(filtered);
        }
    }, [
        supportChains,
        supportUsdcChains,
        searchQuery,
        selectedFromNetwork,
        selectedToNetwork,
    ]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "relative w-full mx-auto my-4", role: "search", children: [_jsx("input", { className: "w-full border-2 text-neutral-400 dark:text-neutral-200 dark:border-neutral-700 h-10 pr-5 pl-12 rounded-8 text-label_l font-medium focus:outline-none bg-secondary-10 dark:bg-neutral-900", type: "search", name: "search", placeholder: "Search Network", value: searchQuery, onChange: (e) => setSearchQuery(e.target.value), autoComplete: "off", "aria-label": "Search Network" }), _jsx("div", { className: "absolute left-0 top-0 mt-2 ml-4", children: _jsx("img", { className: "inline", src: isDarkMode ? searchDark : search, alt: "Search Icon", style: {
                                width: '1.5rem',
                                height: '1.5rem',
                            } }) })] }), _jsx("div", { className: `max-h-[322px] dark:text-neutral-0 grid grid-cols-2 mobile:grid-cols-1
        ${!isMainnet ? 'network-search' : ''}`, children: filteredChains.map((chain) => (_jsx("span", { onClick: () => onClick(chain), style: { cursor: 'pointer' }, children: _jsx("div", { className: `p-2 space-x-2 space-y-2 hover:bg-primary-500 hover:dark:text-neutral-800 rounded-8  ${chain.id ===
                            (networkFlag === 'from'
                                ? selectedFromNetwork?.networkID
                                : selectedToNetwork?.networkID)
                            ? 'border-2 border-primary-500 rounded-8'
                            : ''}`, children: _jsxs("button", { type: "button", className: `w-full font-medium flex justify-between items-center`, "aria-label": `Select ${displayChainName(chain)}`, children: [_jsxs("div", { children: [_jsx("img", { className: "inline border border-neutral-800 rounded-[30px]", src: getImage('network/' + getSelectableNetwork(chain.id).img), alt: getSelectableNetwork(chain.id).label, style: {
                                                width: `30px`,
                                                height: `30px`,
                                            } }), _jsx("span", { className: "whitespace-nowrap text-label_l pl-2", children: displayChainName(chain) })] }), _jsx("span", { className: "whitespace-nowrap text-label_s", children: `${balances[chain.id]
                                        ? formatNumberWithFourDecimalPlaces(formatUnits(balances[chain.id].value, balances[chain.id].decimals))
                                        : 0} ${tradeTokenTypeIndex === Token.USDC ? 'USDC' : 'ETH'}` })] }) }) }, chain.id))) })] }));
};
export default NetworkModalContent;
