import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import discordDark from '@/assets/new-icon/sns/mobile/discord-dark.svg';
import discord from '@/assets/new-icon/sns/mobile/discord.svg';
import blogDark from '@/assets/new-icon/sns/mobile/blog-dark.svg';
import blog from '@/assets/new-icon/sns/mobile/blog.svg';
import githubDark from '@/assets/new-icon/sns/mobile/github-dark.svg';
import github from '@/assets/new-icon/sns/mobile/github.svg';
import mediumDark from '@/assets/new-icon/sns/mobile/medium-dark.svg';
import medium from '@/assets/new-icon/sns/mobile/medium.svg';
import xDark from '@/assets/new-icon/sns/mobile/x-dark.svg';
import x from '@/assets/new-icon/sns/mobile/x.svg';
import telegramDark from '@/assets/new-icon/sns/mobile/telegram-dark.svg';
import telegram from '@/assets/new-icon/sns/mobile/telegram.svg';
import { useIsDarkModeContext } from '@/context';
const SnsNav = memo(() => {
    const { isDarkMode } = useIsDarkModeContext();
    return (_jsx("nav", { role: "navigation", children: _jsxs("div", { className: "flex relative items-center justify-around rounded-100 px-3 py-2", children: [_jsx("a", { href: "https://github.com/Pheasant-Network", target: "_blank", rel: "noreferrer", "aria-label": "Github", children: _jsx("img", { src: isDarkMode ? githubDark : github, className: "w-6 mx-2 opacity-40", alt: "Github", width: "24", height: "24" }) }), _jsx("a", { href: "https://x.com/PheasantNetwork", target: "_blank", rel: "noreferrer", "aria-label": "X", children: _jsx("img", { src: isDarkMode ? xDark : x, className: "w-6 mx-2 opacity-40", alt: "X", width: "20", height: "18" }) }), _jsx("a", { href: "https://discord.gg/pRYSEUVERT", target: "_blank", rel: "noreferrer", "aria-label": "Discord", children: _jsx("img", { src: isDarkMode ? discordDark : discord, className: "w-6 mx-2 opacity-40", alt: "Discord", width: "24", height: "18" }) }), _jsx("a", { href: "https://t.me/pheasant_network", target: "_blank", rel: "noreferrer", "aria-label": "Telegram", children: _jsx("img", { src: isDarkMode ? telegramDark : telegram, className: "w-6 mx-2 opacity-40", alt: "Telegram", width: "24", height: "14" }) }), _jsx("a", { href: "https://home.pheasant.network/library", target: "_blank", rel: "noreferrer", "aria-label": "Medium", children: _jsx("img", { src: isDarkMode ? mediumDark : medium, className: "w-6 mx-2 opacity-40", alt: "Medium", width: "24", height: "14" }) }), _jsx("a", { href: "https://docs.pheasant.network", target: "_blank", rel: "noreferrer", "aria-label": "Docs", children: _jsx("img", { src: isDarkMode ? blogDark : blog, className: "w-6 mx-2 opacity-40", alt: "Docs", width: "24", height: "14" }) })] }) }));
});
export default SnsNav;
