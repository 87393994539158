// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification {
  top: calc(var(--header-height) + 0.625rem);
  width: calc((100vw - var(--main-width)) / 2 - 4rem);
}
`, "",{"version":3,"sources":["webpack://./src/components/notification.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,mDAAmD;AACrD","sourcesContent":[".notification {\n  top: calc(var(--header-height) + 0.625rem);\n  width: calc((100vw - var(--main-width)) / 2 - 4rem);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
