import { FrontNetworkProvider, NetworkManager, } from '@pheasant-network/pheasant-sdk';
import { useEffect, useState } from 'react';
const useNetworkManager = () => {
    const [networkManager, setNetworkManager] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const initNetworkManager = async () => {
            try {
                const networkProvider = await FrontNetworkProvider.init(process.env.REACT_APP_ENV);
                const manager = await NetworkManager.init(networkProvider);
                setNetworkManager(manager);
            }
            catch (e) {
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        };
        initNetworkManager();
    }, []);
    return { networkManager, loading };
};
export default useNetworkManager;
