// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.window::after {
  content: '';
}
@media screen and (max-width: 639px) {
  .window {
    width: calc(100% - 2rem);
  }
}

.title {
  z-index: 1;
}
.title::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 9999px;
  z-index: -1;
}
`, "",{"version":3,"sources":["webpack://./src/components/modal.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE,UAAU;AACZ;AACA;EACE,WAAW;EACX,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,qBAAqB;EACrB,WAAW;AACb","sourcesContent":[".window::after {\n  content: '';\n}\n@media screen and (max-width: 639px) {\n  .window {\n    width: calc(100% - 2rem);\n  }\n}\n\n.title {\n  z-index: 1;\n}\n.title::before {\n  content: '';\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  border-radius: 9999px;\n  z-index: -1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
