// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history.history-show {
  top: 6rem;
  bottom: unset;
}
.history.history-hide {
  top: unset;
  bottom: -100px;
  height: calc(var(--history-height) + 100px);
}

@media screen and (max-width: 767px) {
  .history.history-show {
    width: calc(100% - 2rem);
    height: calc(100% - 6.5rem);
    z-index: 10;
  }
  .history.history-hide {
    width: calc(100% - 2rem);
    z-index: auto;
  }
}

.history-scroll {
  height: calc(100% - 3.25rem);
}
.history-scroll::-webkit-scrollbar {
  width: 1rem;
}
.history-scroll::-webkit-scrollbar-track {
  background-color: var(--scroll-bar-bg-color);
  border-radius: 100px;
}
.history-scroll::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-thumb-color);
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  -webkit-transition: 0.4s var(--ease-out-cubic);
  transition: 0.4s var(--ease-out-cubic);
}
.history-scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroll-bar-thumb-hover-color);
}

.table {
  border-spacing: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/domain/history/history.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,aAAa;AACf;AACA;EACE,UAAU;EACV,cAAc;EACd,2CAA2C;AAC7C;;AAEA;EACE;IACE,wBAAwB;IACxB,2BAA2B;IAC3B,WAAW;EACb;EACA;IACE,wBAAwB;IACxB,aAAa;EACf;AACF;;AAEA;EACE,4BAA4B;AAC9B;AACA;EACE,WAAW;AACb;AACA;EACE,4CAA4C;EAC5C,oBAAoB;AACtB;AACA;EACE,+CAA+C;EAC/C,oBAAoB;EACpB,6BAA6B;EAC7B,4BAA4B;EAC5B,8CAAsC;EAAtC,sCAAsC;AACxC;AACA;EACE,qDAAqD;AACvD;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".history.history-show {\n  top: 6rem;\n  bottom: unset;\n}\n.history.history-hide {\n  top: unset;\n  bottom: -100px;\n  height: calc(var(--history-height) + 100px);\n}\n\n@media screen and (max-width: 767px) {\n  .history.history-show {\n    width: calc(100% - 2rem);\n    height: calc(100% - 6.5rem);\n    z-index: 10;\n  }\n  .history.history-hide {\n    width: calc(100% - 2rem);\n    z-index: auto;\n  }\n}\n\n.history-scroll {\n  height: calc(100% - 3.25rem);\n}\n.history-scroll::-webkit-scrollbar {\n  width: 1rem;\n}\n.history-scroll::-webkit-scrollbar-track {\n  background-color: var(--scroll-bar-bg-color);\n  border-radius: 100px;\n}\n.history-scroll::-webkit-scrollbar-thumb {\n  background-color: var(--scroll-bar-thumb-color);\n  border-radius: 100px;\n  border: 5px solid transparent;\n  background-clip: content-box;\n  transition: 0.4s var(--ease-out-cubic);\n}\n.history-scroll::-webkit-scrollbar-thumb:hover {\n  background-color: var(--scroll-bar-thumb-hover-color);\n}\n\n.table {\n  border-spacing: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
