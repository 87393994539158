import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from 'react-transition-group';
const SimpleModal = ({ title, children, onClose, className = '', isOpen, }) => {
    const transitionStyles = {
        entering: { opacity: 0 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    };
    return (_jsx(Transition, { in: isOpen, timeout: 0, appear: true, unmountOnExit: true, children: (state) => (_jsxs("div", { style: {
                ...transitionStyles[state],
                transition: 'opacity',
            }, className: `font-display flex justify-center items-center fixed top-0 left-0 w-full h-full z-40 ${className}`, children: [_jsx("button", { onClick: onClose, className: "cursor-pointer fixed w-full h-full bg-dark dark:bg-ground opacity-20 z-40 top-0 left-0" }), _jsxs("div", { className: `window fixed z-50 w-96 bg-ground dark:bg-custom-light py-4 px-8 rounded-2xl flex flex-col border border-black dark:border-custom-black`, children: [_jsxs("div", { className: "flex justify-between items-center dark:text-custom-white", children: [_jsx("h2", { className: `whitespace-pb-8 nowrap rounded-full text-xl py-2 px-4`, children: title }), _jsx("button", { onClick: onClose, "aria-label": "close modal", className: "flex justify-center items-center", children: "\u2716\uFE0F" })] }), _jsx("hr", { className: "bg-gray-200 dark:bg-custom-line border-none h-px mb-4" }), children] })] })) }));
};
export default SimpleModal;
