import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import './select.css';
import { Option, Select } from '@material-tailwind/react';
import { useState } from 'react';
import triangle from '@/assets/icon/triangle.svg';
import triangleWhite from '@/assets/icon/triangle-white.svg';
import { useIsDarkModeContext, useTradeTokenTypeIndexContext } from '@/context';
import { currencies } from '@/constants';
const Header = ({ isApproved }) => {
    const { isDarkMode } = useIsDarkModeContext();
    const { setTradeTokenTypeIndex } = useTradeTokenTypeIndexContext();
    const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]);
    const handleCurrencyChange = (label) => {
        const selected = currencies.find((currency) => currency.label === label);
        if (selected) {
            setSelectedCurrency(selected);
            setTradeTokenTypeIndex(selected.tokenTypeIndex);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("h1", { className: "text-4xl text-center w-full", children: "Send" }), _jsx("div", { children: _jsx(Select, { id: "currency-select", className: "currency-select z-10 w-32 h-10 right-0 font-medium md:w-28 dark:bg-custom-dark dark:border-custom-black dark:md:bg-custom-light", arrow: _jsx("div", { className: "arrow", children: _jsx("img", { className: "open_indicator", src: isDarkMode ? triangleWhite : triangle, style: {
                                width: '0.75rem',
                                height: '0.5625rem',
                            }, alt: "Open Select" }) }), value: selectedCurrency.label, onChange: (label) => handleCurrencyChange(label), tabIndex: 0, "aria-label": "Select a currency", disabled: isApproved, "aria-disabled": isApproved, children: currencies.map(({ img, label }) => (_jsxs(Option, { value: label, className: "flex items-center min-h-[2.5rem]", "aria-label": label, children: [img && (_jsx("div", { className: "w-6 mr-2 min-w-0 text-center", children: _jsx("img", { alt: "Currency Icon", style: {
                                        width: '1.5rem',
                                        height: '1.625rem',
                                    }, className: "inline", src: img }) })), _jsx("span", { children: label })] }, label))) }) })] }));
};
export default Header;
