import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useIsDarkModeContext } from '@/context';
import { useContentful } from '@/hooks/useContentful';
import { fetchRandomLoadingImage } from '@/utils';
const MessageDesktop = ({ className = '' }) => {
    const { isDarkMode } = useIsDarkModeContext();
    const [randomLoadingImage, setRandomLoadingImage] = useState('');
    const [randomQuote, setRandomQuote] = useState('');
    const { getRandomQuote } = useContentful();
    const fetchRandomQuote = async () => {
        const quote = await getRandomQuote();
        setRandomQuote(quote);
    };
    useEffect(() => {
        const randomGif = fetchRandomLoadingImage(isDarkMode);
        setRandomLoadingImage(randomGif);
        fetchRandomQuote();
    }, [isDarkMode]);
    return (_jsx("div", { className: `mx-auto max-w-full w-full mt-4 md:mt-1 ${className}`, children: _jsxs("div", { className: "flex flex-col items-center mx-auto px-4 py-4 relative rounded-2xl border border-primary bg-ground dark:bg-custom-light dark:border-custom-black", children: [_jsxs("div", { className: "flex flex-row justify-between w-full", children: [_jsx("div", { children: _jsx("h3", { className: "text-primary text-lg text-left", children: "Processing..." }) }), randomLoadingImage && (_jsx("img", { src: randomLoadingImage, alt: "Loading Image", width: "48", height: "48", className: "w-12 max-w-[33.3%] my-0" }))] }), _jsx("p", { className: "text-center text-xs", children: randomQuote })] }) }));
};
export default MessageDesktop;
