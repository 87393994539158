// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-tippy-root] {
  max-width: calc(100% - 10px);
}

.tippy-content {
  padding: 1rem !important;
  background-color: var(--surface-primary-background-color);
  border: 1px solid #232424;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.tippy-box[data-placement^='top'] {
  bottom: -4px;
}

.tippy-box[data-placement^='top'] > .tippy-arrow {
  bottom: -1px;
}

.tippy-box[data-placement^='bottom'] {
  top: 4px;
}

.tippy-box[data-placement^='bottom'] > .tippy-arrow {
  top: -1px;
}

.warning .tippy-content {
  padding: 1rem !important;
  background-color: var(--surface-primary-background-color);
  border: 1px solid var(--error-color);
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.warning .tippy-arrow {
  color: var(--error-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/domain/main/tippy.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,wBAAwB;EACxB,yDAAyD;EACzD,yBAAyB;EACzB,2CAA2C;EAC3C,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,wBAAwB;EACxB,yDAAyD;EACzD,oCAAoC;EACpC,2CAA2C;EAC3C,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":["[data-tippy-root] {\n  max-width: calc(100% - 10px);\n}\n\n.tippy-content {\n  padding: 1rem !important;\n  background-color: var(--surface-primary-background-color);\n  border: 1px solid #232424;\n  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.15);\n  border-radius: 4px;\n}\n\n.tippy-box[data-placement^='top'] {\n  bottom: -4px;\n}\n\n.tippy-box[data-placement^='top'] > .tippy-arrow {\n  bottom: -1px;\n}\n\n.tippy-box[data-placement^='bottom'] {\n  top: 4px;\n}\n\n.tippy-box[data-placement^='bottom'] > .tippy-arrow {\n  top: -1px;\n}\n\n.warning .tippy-content {\n  padding: 1rem !important;\n  background-color: var(--surface-primary-background-color);\n  border: 1px solid var(--error-color);\n  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.15);\n  border-radius: 4px;\n}\n\n.warning .tippy-arrow {\n  color: var(--error-color) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
