function getExplicitInjectedProvider(flag) {
    if (typeof window === 'undefined' || typeof window.ethereum === 'undefined')
        return;
    const providers = window.ethereum.providers;
    return providers
        ? providers.find((provider) => provider[flag])
        : window.ethereum[flag]
            ? window.ethereum
            : undefined;
}
function getWindowProviderNamespace(namespace) {
    const providerSearch = (provider, namespace) => {
        const [property, ...path] = namespace.split('.');
        const _provider = provider[property];
        if (_provider) {
            if (path.length === 0)
                return _provider;
            return providerSearch(_provider, path.join('.'));
        }
    };
    if (typeof window !== 'undefined')
        return providerSearch(window, namespace);
}
export function getInjectedProvider({ flag, namespace, }) {
    if (typeof window === 'undefined')
        return;
    if (namespace) {
        // prefer custom eip1193 namespaces
        const windowProvider = getWindowProviderNamespace(namespace);
        if (windowProvider)
            return windowProvider;
    }
    //const providers = window.ethereum?.providers
    if (flag) {
        const provider = getExplicitInjectedProvider(flag);
        if (provider)
            return provider;
    }
    //   if (typeof providers !== 'undefined' && providers.length > 0)
    //     return providers[0]
    //return window.ethereum
}
