import { jsx as _jsx } from "react/jsx-runtime";
import moon from '@/assets/icon/moon.svg';
import sun from '@/assets/icon/sun.png';
import { useIsDarkModeContext } from '@/context';
import { memo } from 'react';
const DarkModeToggleButton = memo(() => {
    const { isDarkMode, setIsDarkMode } = useIsDarkModeContext();
    return (_jsx("div", { className: "flex justify-center items-center mx-2 h-9 w-9 bg-gray-200 dark:bg-custom-light md:dark:bg-custom-lighter rounded-full cursor-pointer", tabIndex: 0, onKeyDown: (e) => {
            if (e.key === 'Enter') {
                localStorage.setItem('darkMode', !isDarkMode ? 'true' : 'false');
                setIsDarkMode(!isDarkMode);
            }
        }, children: _jsx("img", { className: "h-6", src: isDarkMode ? sun : moon, onClick: () => {
                localStorage.setItem('darkMode', !isDarkMode ? 'true' : 'false');
                setIsDarkMode(!isDarkMode);
            }, alt: "Moon Icon", style: {
                width: '0.8125rem',
                height: '0.8125rem',
            } }) }));
});
export default DarkModeToggleButton;
