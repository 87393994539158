import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAccount } from 'wagmi';
import AccountButton from '@/domain/header/AccountButton';
import DarkModeToggleButton from '@/domain/header/DarkModeToggleButton';
import NetworkToggleButton from '@/domain/header/NetworkToggleButton';
const HeaderButtons = ({ isWalletModalOpen, setIsWalletModalOpen }) => {
    const { isConnected } = useAccount();
    return (_jsxs("div", { className: "header-buttons flex items-center", children: [_jsx(DarkModeToggleButton, {}), _jsx(NetworkToggleButton, {}), !isConnected && (_jsx("div", { children: _jsx("button", { className: "button bg-primary header-button relative md:px-4 dark:text-custom-black", type: "button", onClick: () => setIsWalletModalOpen(!isWalletModalOpen), children: _jsx("span", { children: "Connect Wallet" }) }) })), isConnected && _jsx(AccountButton, {})] }));
};
export default HeaderButtons;
