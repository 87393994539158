import * as contentful from 'contentful';
import { useCallback, useEffect, useMemo, useState } from 'react';
export const useContentful = () => {
    const [news, setNews] = useState([]);
    const [quotes, setQuotes] = useState([]);
    const newsClient = useMemo(() => {
        return contentful.createClient({
            space: process.env.REACT_APP_CONTENTFUL_NEWS_SPACE,
            accessToken: process.env.REACT_APP_CONTENTFUL_NEWS_ACCESS_TOKEN,
        });
    }, []);
    const quotesClient = useMemo(() => {
        return contentful.createClient({
            space: process.env.REACT_APP_CONTENTFUL_QUOTES_SPACE,
            accessToken: process.env.REACT_APP_CONTENTFUL_QUOTES_ACCESS_TOKEN,
        });
    }, []);
    const getAllnews = useCallback(async () => {
        try {
            const entries = await newsClient.getEntries();
            const news = entries.items.map((item) => {
                return item.fields.news;
            });
            setNews(news);
        }
        catch (error) {
            console.log(error);
        }
    }, [newsClient]);
    const getAllquotes = useCallback(async () => {
        try {
            const entries = await quotesClient.getEntries();
            const quotes = entries.items.map((item) => {
                return item.fields.quote;
            });
            setQuotes(quotes);
        }
        catch (error) {
            console.log(error);
        }
    }, [quotesClient]);
    const memoizedQuotes = useMemo(() => {
        return quotes;
    }, [quotes]);
    const getRandomQuote = () => {
        try {
            if (memoizedQuotes?.length > 0) {
                const randomQuote = memoizedQuotes[Math.floor(Math.random() * memoizedQuotes.length)];
                console.log(randomQuote);
                return randomQuote;
            }
            return '';
        }
        catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getAllquotes();
    }, []);
    return {
        news,
        getAllnews,
        quotes,
        getAllquotes,
        getRandomQuote,
    };
};
