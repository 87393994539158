import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { getImage } from '@/utils';
import Modal from '@/components/Modal';
import { useAccount } from 'wagmi';
import { WALLETS } from '@/constants';
import reload from '@/assets/new-icon/reload.svg';
import arrow from '@/assets/new-icon/arrow-left.svg';
import arrowDark from '@/assets/new-icon/arrow-left-dark.svg';
import { useIsDarkModeContext } from '@/context';
const WalletNotDetectedModal = memo(({ isOpen, setIsOpen, setIsWalletModalOpen, walletName }) => {
    const { isDarkMode } = useIsDarkModeContext();
    const { isConnected } = useAccount();
    return (_jsx(Modal, { title: "Not Detected", onClose: () => {
            setIsOpen(false);
        }, withButton: true, isOpen: isOpen && !isConnected, isMinHeight: true, isWalletConnect: true, isBigScreen: true, shouldTransition: false, children: _jsxs("div", { className: "flex flex-col items-center justify-center", children: [_jsx("img", { className: "mb-2", src: getImage('wallet/' +
                        WALLETS.find((wallet) => wallet.name === walletName).img), alt: "Wallet Icon", style: {
                        width: '30px',
                        height: '30px',
                    } }), _jsx("span", { className: "text-center text-label_l mobile:text-label_m font-medium dark:text-neutral-0 pt-1", children: walletName }), _jsxs("p", { className: "text-paragraph_m mobile:text-paragraph_s dark:text-neutral-0 py-4 text-left", children: [walletName, " is not detected on your device. If it is installed, try reloading the screen once or make sure it is active. If it is still not detected, disable all other wallet extensions that may be in conflict. If it is not installed, download the wallet from the official website."] }), _jsxs("div", { className: "w-full flex mobile:flex-col justify-center items-center gap-4 text-label_l font-semibold", children: [_jsxs("button", { className: "w-full border border-neutral-800 bg-neutral-0 hover:bg-secondary-10 dark:bg-secondary-900 dark:hover:bg-secondary-800 dark:border-neutral-700 rounded-8 py-[14px] px-3 flex justify-center items-center", onClick: () => {
                                setIsOpen(false);
                                setIsWalletModalOpen(true);
                            }, "aria-label": "Back", children: [_jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "Back Icon", className: "mr-1", style: {
                                        width: '20px',
                                        height: '20px',
                                    } }), _jsx("span", { children: "Back" })] }), _jsxs("button", { className: "w-full bg-primary-500 dark:bg-primary-400 dark:hover:bg-primary-300 dark:text-neutral-800 hover:bg-primary-600 rounded-8 py-[14px] px-3 flex justify-center items-center", onClick: () => {
                                window.location.reload();
                            }, "aria-label": "Reload", children: [_jsx("img", { src: reload, alt: "Reload Icon", className: "mr-1", style: {
                                        width: '20px',
                                        height: '20px',
                                    } }), _jsx("span", { children: "Reload" })] })] })] }) }));
});
export default WalletNotDetectedModal;
