// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* swap button emphasis */
.highlight {
  outline: 2px solid;
  outline-offset: 0px;
  outline-color: var(--primary-color);
  animation: highlight-animation 1.6s ease-out infinite forwards;
}

.action-button {
  color: var(--pheasant-black-color);
}
@keyframes highlight-animation {
  0% {
    outline-offset: 0px;
    outline-color: var(--primary-color);
  }
  60% {
    outline-offset: 5px;
    outline-color: rgba(255, 255, 255, 0);
  }
  100% {
    outline-offset: 5px;
    outline-color: rgba(255, 255, 255, 0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/domain/main/footer.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,kBAAkB;EAClB,mBAAmB;EACnB,mCAAmC;EACnC,8DAA8D;AAChE;;AAEA;EACE,kCAAkC;AACpC;AA4CA;EACE;IACE,mBAAmB;IACnB,mCAAmC;EACrC;EACA;IACE,mBAAmB;IACnB,qCAAqC;EACvC;EACA;IACE,mBAAmB;IACnB,qCAAqC;EACvC;AACF","sourcesContent":["/* swap button emphasis */\n.highlight {\n  outline: 2px solid;\n  outline-offset: 0px;\n  outline-color: var(--primary-color);\n  animation: highlight-animation 1.6s ease-out infinite forwards;\n}\n\n.action-button {\n  color: var(--pheasant-black-color);\n}\n\n@-moz-keyframes highlight-animation {\n  0% {\n    outline-offset: 0px;\n    outline-color: var(--primary-color);\n  }\n  60% {\n    outline-offset: 5px;\n    outline-color: rgba(255, 255, 255, 0);\n  }\n  100% {\n    outline-offset: 5px;\n    outline-color: rgba(255, 255, 255, 0);\n  }\n}\n@-webkit-keyframes highlight-animation {\n  0% {\n    outline-offset: 0px;\n    outline-color: var(--primary-color);\n  }\n  60% {\n    outline-offset: 5px;\n    outline-color: rgba(255, 255, 255, 0);\n  }\n  100% {\n    outline-offset: 5px;\n    outline-color: rgba(255, 255, 255, 0);\n  }\n}\n@-o-keyframes highlight-animation {\n  0% {\n    outline-offset: 0px;\n    outline-color: var(--primary-color);\n  }\n  60% {\n    outline-offset: 5px;\n    outline-color: rgba(255, 255, 255, 0);\n  }\n  100% {\n    outline-offset: 5px;\n    outline-color: rgba(255, 255, 255, 0);\n  }\n}\n@keyframes highlight-animation {\n  0% {\n    outline-offset: 0px;\n    outline-color: var(--primary-color);\n  }\n  60% {\n    outline-offset: 5px;\n    outline-color: rgba(255, 255, 255, 0);\n  }\n  100% {\n    outline-offset: 5px;\n    outline-color: rgba(255, 255, 255, 0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
