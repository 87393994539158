import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useIsDarkModeContext } from '@/context';
import { useContentful } from '@/hooks/useContentful';
import { fetchRandomLoadingImage } from '@/utils';
const MessageDesktop = ({ className = '' }) => {
    const { isDarkMode } = useIsDarkModeContext();
    const [randomLoadingImage, setRandomLoadingImage] = useState('');
    const [randomQuote, setRandomQuote] = useState('');
    const { getRandomQuote } = useContentful();
    const fetchRandomQuote = async () => {
        const quote = await getRandomQuote();
        setRandomQuote(quote);
    };
    useEffect(() => {
        const randomGif = fetchRandomLoadingImage(isDarkMode);
        setRandomLoadingImage(randomGif);
        fetchRandomQuote();
    }, [isDarkMode]);
    return (_jsx("div", { className: `message font-display mx-auto max-w-xs fixed right-8 ${className}`, children: _jsxs("div", { className: "mx-auto relative rounded-xl border border-primary dark:border-custom-black bg-ground shadow-md dark:bg-custom-light", children: [_jsx("h3", { className: "bg-primary text-xl py-2 rounded-t-lg w-full text-dark text-center z-0", children: "Processing..." }), _jsxs("div", { className: "w-full mt-5 mb-4 px-6 text-center", children: [randomLoadingImage && (_jsx("img", { src: randomLoadingImage, alt: "Loading Image", width: "80", height: "80", className: "h-20 w-auto my-0 inline-block" })), _jsx("p", { className: "mt-4 text-center dark:text-custom-white", children: randomQuote })] })] }) }));
};
export default MessageDesktop;
