import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIsVisibleNewsBanner } from '@/context';
import { useContentful } from '@/hooks/useContentful';
import arrow from '@/assets/new-icon/external-link-arrow.svg';
import close from '@/assets/new-icon/close.svg';
const options = {
    renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
            return (_jsx("span", { children: _jsxs("a", { target: "_blank", rel: "noopener noreferrer", href: node.data.uri, className: "text-text_link_l mobile:text-text_link_m underline hover:no-underline", children: [children, _jsx("img", { src: arrow, alt: "arrow", className: "inline", width: "16px", height: "16px" })] }) }));
        },
    },
};
const NewsBar = styled.div `
  width: 100%;
  height: 3.25rem;
  background: linear-gradient(89.9deg, #00c9a2 -0.05%, #00c942 100%),
    linear-gradient(89.81deg, #00edbf -0.1%, #00c641 108.33%);
  position: relative;
`;
const NewsBanner = memo(() => {
    const [isVisible, setIsVisible] = useState(true);
    const { getAllnews, news, fields } = useContentful();
    const { setIsVisibleNewsBanner } = useIsVisibleNewsBanner();
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    useEffect(() => {
        getAllnews();
    }, []);
    useEffect(() => {
        setIsVisibleNewsBanner(news && news.length > 0 && isVisible);
    }, [isVisible, news]);
    return (_jsx(_Fragment, { children: news && news.length > 0 && isVisible && (_jsx(NewsBar, { "aria-label": "News", children: _jsxs("div", { className: "inner-content flex h-full items-center px-4", children: [_jsx("div", { className: "flex-grow text-center text-label_l mobile:text-label_m dark:text-neutral-800", children: documentToReactComponents(fields[fields.length - 1], options) }), _jsx("button", { children: _jsx("img", { src: close, alt: "close", className: "w-6 h-6", onClick: toggleVisibility }) })] }) })) }));
});
export default NewsBanner;
