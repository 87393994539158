import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import '@/app.css';
import '@/header.css';
import '@/domain/history/history.css';
import '@/domain/main/main.css';
import '@/components/notification.css';
import '@/components/modal.css';
import '@/components/messageDesktop.css';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import bgLeftDark from '@/assets/new-icon/bg-left-dark.svg';
import bgLeftLight from '@/assets/new-icon/bg-left-light.svg';
import bgRightDark from '@/assets/new-icon/bg-right-dark.svg';
import bgRightLight from '@/assets/new-icon/bg-right-light.svg';
import Modal from '@/components/Modal';
import Teleport from '@/components/Teleport';
import { PhaseType, useCurrentTxInfo, useIsDarkModeContext, useModalContext, usePhaseContext, } from '@/context';
import HistoryCard from '@/domain/history/HistoryCard';
import MainCard from '@/domain/main/MainCard';
import NewsBanner from '@/domain/news/NewsBanner';
import Header from '@/Header';
import { useTrade as useTradeMainnet } from '@/hooks/mainnet/useTrade';
import { useTrade as useTradeTestnet } from '@/hooks/testnet/useTrade';
import SnsNav from '@/SnsNav';
import CompleteModalContent from './components/CompleteModalContent';
import Loading from './components/Loading';
import ProcessingModalContent from './components/ProcessingModalContent';
import SimpleModal from './components/SimpleModal';
import { constants } from './constants';
const useTrade = process.env.REACT_APP_IS_MAINNET === 'true'
    ? useTradeMainnet
    : useTradeTestnet;
const intialTxInfo = {
    address: '',
    status: 0,
    timestamp: Date.now(),
    txhash: undefined,
    destCode: 0,
    acceptTxHash: undefined,
    acceptedTxHash: undefined,
    newTradeTxHash: undefined,
    burnTxHash: undefined,
    mintTxHash: undefined,
    amount: '0',
    fee: '0',
    chainName: '',
    tokenTypeIndex: 0,
    sourceNetworkId: 0,
    destinationNetworkId: 0,
    estimateTime: { minutes: 0, seconds: 0 },
    tradeType: undefined,
};
export const App = () => {
    const { isDarkMode } = useIsDarkModeContext();
    const { phase } = usePhaseContext();
    const { setCurrentTxInfo } = useCurrentTxInfo();
    const { isProcessingModalOpen, setIsProcessingModalOpen, isCompleteModalOpen, setIsCompleteModalOpen, isTimedOutModalOpen, setIsTimedOutModalOpen, isUnknownErrorModalOpen, setIsUnknownErrorModalOpen, } = useModalContext();
    const { getEstimatedAmount, isFeeCalculated, totalFee, setAmountStr, estimatedReceived, estimatedUsed, selectFromNetwork, selectedToNetworkHandler, isApproved, isFirstFeeCalculated, setIsFirstFeeCalculated, txhash, prepare, executeTradeTemporarily, executeTrade, approve, isInitialized, recipient, setRecipient, isTemporarySetting, isDeposited, isProcessing, isValidated, setIsValidated, validateErrorMsg, cancelTrade, thresholds, usdcThresholds, isCctpTrade, resetInput, } = useTrade();
    const { address, isConnected } = useAccount();
    const [prevAddress, setPrevAddress] = useState(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    // TODO change when maintenance starts
    const [isOpenMaintenanceModal, setIsOpenMaintenanceModal] = useState(false);
    // when account changed, reload page
    useEffect(() => {
        if (isConnected && address !== prevAddress && prevAddress !== null) {
            window.location.reload();
        }
        if (!isConnected) {
            setPrevAddress(null);
        }
        else {
            setPrevAddress(address);
        }
    }, [address, isConnected]);
    useEffect(() => {
        const getViewportHeight = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        // 画面サイズが変更されたら--vhを更新
        window.addEventListener('resize', () => {
            getViewportHeight();
        });
        return () => {
            window.removeEventListener('resize', () => {
                getViewportHeight();
            });
        };
    }, []);
    useEffect(() => {
        const confirmSave = (e) => {
            if (phase !== PhaseType.NOTHING_STARTED) {
                e.preventDefault();
                e.returnValue = '';
            }
        };
        // ページ離脱前にアラートを出す
        window.addEventListener('beforeunload', (event) => confirmSave(event));
        return () => {
            window.removeEventListener('beforeunload', (event) => confirmSave(event));
        };
    }, [phase]);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                setIsProcessingModalOpen(false);
                setIsTimedOutModalOpen(false);
                setIsCompleteModalOpen(false);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    // Handling of URLs typed directly in new tabs
    useEffect(() => {
        const isFirstLoad = sessionStorage.getItem('isFirstLoad')
            ? sessionStorage.getItem('isFirstLoad') === 'true'
                ? true
                : false
            : true;
        setIsFirstLoad(isFirstLoad);
        if (isFirstLoad) {
            sessionStorage.setItem('isFirstLoad', 'false');
            window.location.reload();
        }
        return () => {
            sessionStorage.removeItem('isFirstLoad');
        };
    }, []);
    // For wallets thorough WalletConnect to show correct image
    // const { connector, isReconnecting } = getAccount()
    // const { setWalletImagePath } = useWalletImagePath()
    // useEffect(() => {
    //   console.log({ connector, isReconnecting})
    //   if (connector && connector.name === "WalletConnect") {
    //     const storedItems = localStorage.getItem('walletConnectImagePath')
    //     if (storedItems) {
    //       console.log(storedItems)
    //       const walletImagePath = JSON.parse(storedItems)
    //       console.log(walletImagePath)
    //       setWalletImagePath(walletImagePath)
    //     }
    //   }
    // }, [isReconnecting])
    const renderApp = () => {
        return (_jsxs("div", { className: "overflow-hidden", children: [_jsx(NewsBanner, {}), _jsx(Header, {}), _jsxs("div", { className: "flex justify-center items-center", children: [_jsx("div", { className: "ml-6 mobile:hidden", children: _jsx("img", { src: isDarkMode ? bgLeftDark : bgLeftLight, alt: "Github", width: "286", height: "490" }) }), _jsx(MainCard, { isCompleteModalOpen: isCompleteModalOpen, getEstimatedAmount: getEstimatedAmount, selectFromNetwork: selectFromNetwork, totalFee: totalFee, isFeeCalculated: isFeeCalculated, selectedToNetworkHandler: selectedToNetworkHandler, setAmountStr: setAmountStr, estimatedReceived: estimatedReceived, estimatedUsed: estimatedUsed, isFirstFeeCalculated: isFirstFeeCalculated, setIsFirstFeeCalculated: setIsFirstFeeCalculated, txhash: txhash, prepare: prepare, executeTradeTemporarily: executeTradeTemporarily, executeTrade: executeTrade, approve: approve, isApproved: isApproved, isInitialized: isInitialized, recipient: recipient, setRecipient: setRecipient, isTemporarySetting: isTemporarySetting, isDeposited: isDeposited, isProcessing: isProcessing, isValidated: isValidated, setIsValidated: setIsValidated, validateErrorMsg: validateErrorMsg, thresholds: thresholds, usdcThresholds: usdcThresholds, isCctpTrade: isCctpTrade, resetInput: resetInput }), _jsx("div", { className: "mt-40 mr-6 mobile:hidden", children: _jsx("img", { src: isDarkMode ? bgRightDark : bgRightLight, alt: "Github", width: "286", height: "490" }) })] }), _jsx(Teleport, { children: _jsx(SimpleModal, { title: "In Progress...", onClose: () => {
                            setIsProcessingModalOpen(false);
                        }, isOpen: isProcessingModalOpen, children: _jsx(_Fragment, { children: _jsx(ProcessingModalContent, { executeTrade: executeTrade }) }) }) }), _jsx(Teleport, { children: _jsx(SimpleModal, { title: "Completed!", onClose: () => {
                            setIsCompleteModalOpen(false);
                            setCurrentTxInfo(intialTxInfo);
                        }, isOpen: isCompleteModalOpen, children: _jsx(_Fragment, { children: _jsx(CompleteModalContent, {}) }) }) }), _jsx(Teleport, { children: _jsx(Modal, { isOpen: isTimedOutModalOpen, title: "Error", withButton: true, onClose: () => setIsTimedOutModalOpen(false), className: "modal__processing", isAlert: true, children: _jsx("p", { className: "mx-auto dark:text-textColor-custom-white", children: "Temporary error occurred (transaction timeout, network down, etc\u2026). No need to worry, no gas cost. Please send again. If this happens repeatedly, please ask on Discord." }) }) }), _jsx(Teleport, { children: _jsx(Modal, { isOpen: isOpenMaintenanceModal, title: "Under maintenance", withButton: false, onClose: () => { }, className: "modal__processing", children: _jsx("p", { className: "mx-auto dark:text-textColor-custom-white", children: "The system is currently under maintenance at 12am-4am UTC. Thank you for your understanding." }) }) }), _jsx(Teleport, { children: _jsx(Modal, { isOpen: isUnknownErrorModalOpen, title: "Error", withButton: true, onClose: () => setIsUnknownErrorModalOpen(false), className: "modal__processing", isAlert: true, children: _jsx("p", { className: "mx-auto dark:text-textColor-custom-white", children: constants.UNKNOWN_ERROR }) }) }), _jsx(HistoryCard, { cancelTrade: cancelTrade }), _jsx(SnsNav, {})] }));
    };
    return (_jsx(_Fragment, { children: isFirstLoad ? (_jsx("div", { className: "flex justify-center items-center h-full", children: _jsx(Loading, {}) })) : (renderApp()) }));
};
