import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import { useIsDarkModeContext, useModalContext } from '@/context';
import { useContentful } from '@/hooks/useContentful';
import { fetchRandomLoadingImageData, isMobileDevice, } from '@/utils';
import Modal from '@/components/Modal';
import Teleport from '@/components/Teleport';
const ProcessingModal = memo(() => {
    const { isProcessingModalOpen, setIsProcessingModalOpen } = useModalContext();
    const { isDarkMode } = useIsDarkModeContext();
    const { getRandomQuote } = useContentful();
    const initialData = {
        url: '',
        width: '',
        height: '',
        shouldMargin: false,
    };
    const [randomLoadingImage, setRandomLoadingImageData] = useState(initialData);
    const [randomQuote, setRandomQuote] = useState('');
    useEffect(() => {
        const randomGifData = fetchRandomLoadingImageData(isDarkMode);
        setRandomLoadingImageData(randomGifData);
    }, [isProcessingModalOpen, isDarkMode]);
    useEffect(() => {
        const randomQuote = getRandomQuote();
        setRandomQuote(randomQuote);
    }, [isProcessingModalOpen]);
    return (_jsx(Teleport, { children: _jsxs(Modal, { isOpen: isProcessingModalOpen, title: "Processing...", withButton: true, onClose: () => setIsProcessingModalOpen(false), className: "modal__processing", isDesktopProcessing: !isMobileDevice(), children: [randomLoadingImage.url && (_jsx("img", { src: randomLoadingImage.url, alt: "Loading Animation", className: `${randomLoadingImage.shouldMargin && 'my-10'} mx-auto`, style: {
                        width: randomLoadingImage.width,
                        height: randomLoadingImage.height,
                    } })), _jsx("p", { className: "mt-4 text-center dark:text-custom-white", children: randomQuote })] }) }));
});
export default ProcessingModal;
