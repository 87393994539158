import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import styled from 'styled-components';
import arrow from '@/assets/new-icon/external-link-arrow.svg';
import arrowDark from '@/assets/new-icon/external-link-arrow-dark.svg';
import { useIsDarkModeContext } from '@/context';
const StyledCheckbox = styled.input `
  &:checked ~ .dot {
    transform: translateX(90%);
  }
`;
const Dot = styled.div `
  width: 5rem;
  height: 2rem;
  top: 0.125rem;
  left: 0.125rem;
  padding: 9px 21px;
  border-radius: 30px;
  border: 2px solid #00c9a2;
  gap: 2px;
`;
const NetworkToggleButton = memo(() => {
    const { isDarkMode } = useIsDarkModeContext();
    const isMainnet = process.env.REACT_APP_IS_MAINNET === 'true';
    return (_jsx("label", { htmlFor: "toggle-testnet", className: "flex items-center cursor-pointer pr-4", tabIndex: 0, onKeyDown: (e) => {
            if (e.key === 'Enter') {
                const newTabUrl = !isMainnet
                    ? 'https://pheasant.network/'
                    : 'https://testnet.pheasant.network/';
                window.open(newTabUrl, '_blank');
            }
        }, children: _jsxs("div", { className: "relative", children: [_jsx(StyledCheckbox, { type: "checkbox", id: "toggle-testnet", className: "sr-only", onChange: () => {
                        const newTabUrl = !isMainnet
                            ? 'https://pheasant.network/'
                            : 'https://testnet.pheasant.network/';
                        window.open(newTabUrl, '_blank');
                    }, checked: !isMainnet, tabIndex: -1, "aria-checked": !isMainnet }), _jsxs("div", { className: "bg-neutral-100 dark:bg-neutral-700 mobile:dark:bg-custom-lighter w-[9.438rem] h-9 rounded-100 border dark:border-none flex justify-around items-center", children: [_jsx("span", { className: `text-label_s font-medium left-3 bottom-2 ${isMainnet ? 'text-transparent' : 'text-neutral-600'}`, children: "Mainnet" }), _jsx("span", { className: `text-label_s font-medium left-3 bottom-2 ${!isMainnet ? 'text-transparent' : 'text-neutral-600'}`, children: "Testnet" })] }), _jsxs(Dot, { className: "dot absolute text-label_s font-medium rounded-100 flex justify-center items-center bg-neutral-0 dark:bg-secondary-900", children: [isMainnet ? 'Mainnet' : 'Testnet', _jsx("img", { src: isDarkMode ? arrowDark : arrow, alt: "arrow", width: "12px", height: "12px" })] })] }) }));
});
export default NetworkToggleButton;
