import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import '@/assets/common.css';
import { createRoot } from 'react-dom/client';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import * as allChains from 'wagmi/chains';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { InjectedConnector } from '@wagmi/core';
import { publicProvider } from 'wagmi/providers/public';
import { ContextProvider } from '@/context';
import { App } from './App';
import { NETWORKS, TOP_NETWORK_ID, modeMainnet, taikoHekla, xLayerTestnet, amoy, cardona, lineaSepolia, xLayer, zkSyncSepolia, morphHolesky, zircuitTestnet, 
//mantleTestnet,
taiko, fhenixTestnet, unichainSepolia, morph } from './constants';
import { getInjectedProvider } from './utils/injectedWallet';
// const arbitrumSepolia = {
//   id: 421614,
//   name: 'Arbitrum Sepolia',
//   network: 'arbitrum-sepolia',
//   nativeCurrency: {
//     name: 'Arbitrum Sepolia Ether',
//     symbol: 'ETH',
//     decimals: 18,
//   },
//   rpcUrls: {
//     default: {
//       http: ['https://sepolia-rollup.arbitrum.io/rpc'],
//     },
//     public: {
//       http: [process.env.REACT_APP_ARBITRUM_SEPOLIA_ENDPOINT],
//     },
//   },
//   blockExplorers: {
//     default: {
//       name: 'Blockscout',
//       url: 'https://sepolia-explorer.arbitrum.io',
//     },
//   },
// } as const satisfies Chain
const chainsArray = Object.values(allChains).map((chain) => {
    // if (chain.id === 167007) {
    //   return taikoJolnir
    // }
    // else if (chain.id === 421614) {
    //   console.log(chain)
    //   return arbitrumSepolia
    // }
    //else {
    return chain;
    //}
});
//@ts-ignore
chainsArray.push(xLayerTestnet);
//@ts-ignore
chainsArray.push(modeMainnet);
//@ts-ignore
chainsArray.push(amoy);
//@ts-ignore
chainsArray.push(cardona);
//@ts-ignore
chainsArray.push(lineaSepolia);
//@ts-ignore
chainsArray.push(xLayer);
//@ts-ignore
chainsArray.push(taikoHekla);
//@ts-ignore
chainsArray.push(zkSyncSepolia);
//@ts-ignore
//chainsArray.push(mantleTestnet)
//@ts-ignore
chainsArray.push(morphHolesky);
//@ts-ignore
chainsArray.push(zircuitTestnet);
//@ts-ignore
chainsArray.push(taiko);
//@ts-ignore
chainsArray.push(fhenixTestnet);
//@ts-ignore
chainsArray.push(unichainSepolia);
//@ts-ignore
chainsArray.push(morph);
const { chains, publicClient, webSocketPublicClient } = configureChains(chainsArray, [publicProvider()]);
const moveToTopById = (arr) => {
    const index = arr.findIndex((item) => item.id === TOP_NETWORK_ID);
    if (index !== -1) {
        const [item] = arr.splice(index, 1);
        arr.unshift(item);
    }
    return arr;
};
export let supportChains = [];
chains.forEach((chain) => {
    NETWORKS.forEach((network) => {
        if (chain.id === network.networkID) {
            supportChains.push(chain);
        }
    });
});
export let supportUsdcChains = [];
chains.forEach((chain) => {
    NETWORKS.forEach((network) => {
        if (chain.id === network.networkID && network.isUsdc) {
            supportUsdcChains.push(chain);
        }
    });
});
supportChains = moveToTopById(supportChains);
supportUsdcChains = moveToTopById(supportUsdcChains);
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: [
        new MetaMaskConnector({ chains: supportChains }),
        new CoinbaseWalletConnector({
            chains: supportChains,
            options: {
                appName: 'Pheasant Network',
            },
        }),
        new InjectedConnector({
            chains,
            options: {
                name: 'Rabby Wallet',
                getProvider: () => getInjectedProvider({ flag: 'isRabby' }),
                shimDisconnect: true,
            },
        }),
        new InjectedConnector({
            chains,
            options: {
                name: 'OKX Wallet',
                getProvider: () => getInjectedProvider({ namespace: 'okxwallet' }),
                shimDisconnect: true,
            },
        }),
        new InjectedConnector({
            chains,
            options: {
                name: 'Bitget Wallet',
                getProvider: () => getInjectedProvider({
                    namespace: 'bitkeep.ethereum',
                    flag: 'isBitKeep',
                }),
                shimDisconnect: true,
            },
        }),
        new WalletConnectConnector({
            chains: supportChains,
            options: {
                projectId: process.env.REACT_APP_WALLET_CONNECT_ID,
            },
        }),
        new InjectedConnector({
            chains,
            options: {
                name: 'Taho Wallet',
                getProvider: () => getInjectedProvider({
                    namespace: 'tally',
                    flag: 'isTally',
                }),
                shimDisconnect: true,
            },
        }),
        // new InjectedConnector({
        //   chains,
        //   options: {
        //     name: 'Rainbow Wallet',
        //     getProvider: () => getInjectedProvider({ flag: 'isRainbow' }),
        //     shimDisconnect: true,
        //   },
        // }),
        // new InjectedConnector({
        //   chains,
        //   options: {
        //     name: 'Trust Wallet',
        //     getProvider: () => getInjectedProvider({ flag: 'isTrustWallet' }),
        //     shimDisconnect: true,
        //   },
        // }),
        // new InjectedConnector({
        //   chains,
        //   options: {
        //     name: 'Zerion Wallet',
        //     getProvider: () => getInjectedProvider({
        //       namespace: 'zerionWallet',
        //       flag: 'isZerion',
        //     }),
        //     shimDisconnect: true,
        //   },
        // }),
        // new InjectedConnector({
        //   chains,
        //   options: {
        //     name: 'Token Pocket',
        //     getProvider: () => getInjectedProvider({
        //       flag: 'isTokenPocket',
        //     }),
        //     shimDisconnect: true,
        //   },
        // }),
        // new InjectedConnector({
        //   chains,
        //   options: {
        //     name: 'Coin98 Wallet',
        //     getProvider: () => getInjectedProvider({
        //       namespace: 'coin98Wallet',
        //       flag: 'isCoin98',
        //     }),
        //     shimDisconnect: true,
        //   },
        // }),
    ],
    publicClient,
    webSocketPublicClient,
});
const root = createRoot(document.getElementById('root'));
root.render(_jsx(_Fragment, { children: _jsx(WagmiConfig, { config: wagmiConfig, children: _jsx(ContextProvider, { children: _jsx(App, {}) }) }) }));
