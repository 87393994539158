import { NETWORKS, blockExplorers } from '@/constants';
import { useInstance } from './useInstance';
import { PheasantNetworkBridgeChild, currentNetworkList, Util, Token, } from '@pheasant-network/pheasant-sdk';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { useCurrentTxInfo, useIsFinishedTrade } from '@/context';
import axios from 'axios';
import { useNetworkManagerContext } from '@/context/NetworkManagerContext';
export const useHistory = () => {
    //const { switchNetwork } = useSwitchChain()
    // const { composeExplorerUrl } = useTrade()
    // const { chain: currentChain } = useAccount()
    // const { setPhase } = usePhaseContext()
    // const [txEvents, setTxEvents] = useState<any[]>([])
    // const [evidence, setEvidence] = useState<{ [key: number]: EvidenceItem }>({})
    // const [isProcessing, setIsProcessing] = useState(false)
    // const { setIsProcessingModalOpen, setIsNoticeModalOpen } = useModalContext()
    const isMainnet = process.env.REACT_APP_IS_MAINNET === 'true';
    const { address, isConnected } = useAccount();
    const { allForCalls, allContractAddressList, archiveAllContractAddressList, isInitialized, } = useInstance();
    const { isFinishedTrade, setIsFinishedTrade } = useIsFinishedTrade();
    const { currentTxInfo } = useCurrentTxInfo();
    const [tradeList, setTradeList] = useState([]);
    const [archiveTradeList, setArchiveTradeList] = useState([]);
    const [cctpTradeList, setcctpTradeList] = useState([]);
    const [isFetchedTradeList, setIsFetchedTradeList] = useState(false);
    // custom hook
    const networkManager = useNetworkManagerContext();
    useEffect(() => {
        if (isConnected && isInitialized) {
            initializeHistory();
        }
    }, [isConnected, isInitialized]);
    useEffect(() => {
        if (isFinishedTrade) {
            initializeHistory();
            setIsFinishedTrade(false);
        }
    }, [isFinishedTrade]);
    const initializeHistory = async () => {
        try {
            console.log('start the getTradeList');
            setIsFetchedTradeList(false);
            //await getArchiveTradeList()
            //console.log('Finish the getArchiveTradeList')
            await getCctpTradeList();
            await getTradeList();
            console.log('Finish the getTradeList');
            setIsFetchedTradeList(true);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getArchiveTradeList = async () => {
        let tmpTradeList = [];
        if (Object.keys(archiveAllContractAddressList).length === 0)
            return;
        for (const network of NETWORKS) {
            if (network.id === 0)
                continue;
            // TODO
            if (network.id !== 2 &&
                network.id !== 3 &&
                network.id !== 4 &&
                network.id !== 9)
                continue;
            const targetNetwork = currentNetworkList.find((obj) => obj.chainId === network.networkID);
            const targetForCall = allForCalls[network.id];
            for (let i = 0; archiveAllContractAddressList['v1'][targetNetwork.projectName] &&
                archiveAllContractAddressList['v1'][targetNetwork.projectName]['pheasantNetworkBridgeAddress'] &&
                i <
                    archiveAllContractAddressList['v1']['polygon']['pheasantNetworkBridgeAddress'].length; i++) {
                const contract_for_call = await PheasantNetworkBridgeChild.init(targetForCall.web3, archiveAllContractAddressList['v1'][targetNetwork.projectName]['pheasantNetworkBridgeAddress'][i], networkManager, 'temp');
                const tmpEachNetworkTradeList = await contract_for_call.getTradeListOld(address);
                const targetPairNetwork = NETWORKS.find((obj) => obj.networkID === targetNetwork.pairNetworkId);
                let eachNetworkTradeList = addArchiveNetworkForTradeList(tmpEachNetworkTradeList, network.label, targetPairNetwork.label);
                tmpTradeList = tmpTradeList.concat(eachNetworkTradeList);
            }
        }
        await sortTradeList(tmpTradeList, archiveTradeList);
    };
    const getTradeList = async () => {
        let tmpTradeList = [];
        let historyApiResult;
        try {
            historyApiResult = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/trades?userAddress=${address}&isTestnet=${isMainnet ? 'false' : 'true'}`);
            console.log({ historyApiResult });
        }
        catch (error) {
            console.log(error);
        }
        let tradeListFromApi = {};
        if (historyApiResult &&
            historyApiResult.data &&
            historyApiResult.data.data &&
            Object.keys(historyApiResult.data.data.tradeList).length > 0) {
            tradeListFromApi = historyApiResult.data.data.tradeList;
            if (currentTxInfo &&
                currentTxInfo.address &&
                currentTxInfo.chainName &&
                (currentTxInfo.acceptedTxHash || currentTxInfo.newTradeTxHash)) {
                tradeListFromApi[currentTxInfo.chainName].push(currentTxInfo);
                if (currentTxInfo.acceptedTxHash) {
                    tradeListFromApi[currentTxInfo.chainName].push(currentTxInfo);
                }
                else if (currentTxInfo.newTradeTxHash) {
                    const isSame = tradeListFromApi[currentTxInfo.chainName].some((trade) => trade.newTradeTxHash === currentTxInfo.newTradeTxHash);
                    if (!isSame)
                        tradeListFromApi[currentTxInfo.chainName].push(currentTxInfo);
                }
            }
        }
        else {
            return;
        }
        for (const network of NETWORKS) {
            if (network.id === 0)
                continue;
            if (networkManager.getL1NetworkChainIds().includes(network.networkID))
                continue;
            const targetNetwork = currentNetworkList.find((obj) => obj.chainId === network.networkID);
            if (!tradeListFromApi[targetNetwork.chainName])
                continue;
            let tmpEachNetworkTradeList = Object.values(tradeListFromApi[targetNetwork.chainName]);
            const targetPairNetwork = NETWORKS.find((obj) => obj.networkID === targetNetwork.pairNetworkId);
            const targetNetworkInfo = networkManager.getNetworkInfoByChainId(targetNetwork.chainId);
            const eachNetworkTradeList = await addNetworkForTradeList(tmpEachNetworkTradeList, network.label, targetPairNetwork.label, targetNetworkInfo.networkCode, network.networkID.toString(), targetPairNetwork.networkID.toString());
            tmpTradeList = tmpTradeList.concat(eachNetworkTradeList);
        }
        tmpTradeList = tmpTradeList.filter((n) => n); //filter empty trade
        tmpTradeList.push(...cctpTradeList);
        console.log({ tmpTradeList });
        await sortTradeList(tmpTradeList, tradeList);
    };
    const getCctpTradeList = async () => {
        let tmpTradeList = [];
        let historyApiResult;
        try {
            historyApiResult = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/cctpTrades?userAddress=${address}&isTestnet=${isMainnet ? 'false' : 'true'}`);
            console.log({ historyApiResult });
        }
        catch (error) {
            console.log(error);
        }
        let tradeListFromApi = {};
        if (historyApiResult &&
            historyApiResult.data &&
            historyApiResult.data.data &&
            Object.keys(historyApiResult.data.data.tradeList).length > 0) {
            tradeListFromApi = historyApiResult.data.data.tradeList;
            if (currentTxInfo &&
                currentTxInfo.address &&
                currentTxInfo.chainName &&
                currentTxInfo.burnTxHash) {
                tradeListFromApi[currentTxInfo.chainName].push(currentTxInfo);
            }
        }
        else {
            return;
        }
        for (const network of NETWORKS) {
            if (network.id === 0)
                continue;
            const targetNetwork = currentNetworkList.find((obj) => obj.chainId === network.networkID);
            if (!tradeListFromApi[targetNetwork.chainName])
                continue;
            let tmpEachNetworkTradeList = Object.values(tradeListFromApi[targetNetwork.chainName]);
            const eachNetworkTradeList = addNetworkForCctpTradeList(tmpEachNetworkTradeList);
            tmpTradeList = tmpTradeList.concat(eachNetworkTradeList);
        }
        tmpTradeList = tmpTradeList.filter((n) => n); //filter empty trade
        console.log({ tmpTradeList });
        await sortTradeList(tmpTradeList, cctpTradeList);
    };
    const sortTradeList = async (tmpTradeList, targetList) => {
        let timestampList = [];
        tmpTradeList.forEach(function (element) {
            timestampList.push(element.timestamp);
        });
        timestampList.sort(function (a, b) {
            return Number(b) - Number(a);
        });
        // timestamp配列の順番に合わせて一致するtradeを入れていく
        for (let j = 0; j < timestampList.length; j++) {
            for (let i = 0; i < tmpTradeList.length; i++) {
                if (timestampList[j] == tmpTradeList[i].timestamp) {
                    targetList[j] = {
                        ...tmpTradeList[i],
                        timestamp: tmpTradeList[i].timestamp,
                        displayAmount: tmpTradeList[i].amount
                            ? tmpTradeList[i].tokenTypeIndex == Token.ETH ||
                                tmpTradeList[i].tokenTypeIndex == undefined // TODO
                                ? Number(Number(Util.fromWei(tmpTradeList[i].amount)).toFixed(7))
                                : Number(Number(Util.fromUSDC(tmpTradeList[i].amount)).toFixed(2))
                            : '-',
                        displayFee: tmpTradeList[i].fee
                            ? tmpTradeList[i].tokenTypeIndex == Token.ETH ||
                                tmpTradeList[i].tokenTypeIndex == undefined // TODO
                                ? Number(Number(Util.fromWei(tmpTradeList[i].fee)).toFixed(7))
                                : Number(Number(Util.fromUSDC(tmpTradeList[i].fee)).toFixed(2))
                            : '-',
                    };
                    break;
                }
            }
        }
    };
    const addArchiveNetworkForTradeList = (tmpTradeList, network1, network2) => {
        let tradeList = [];
        for (let i = 0; i < tmpTradeList.length; i++) {
            if (tmpTradeList[i].isUpward) {
                tradeList[i] = {
                    ...tmpTradeList[i],
                    timestamp: tmpTradeList[i].timestamp * 1000,
                    fromNetwork: network2,
                    toNetwork: network1,
                };
            }
            else {
                tradeList[i] = {
                    ...tmpTradeList[i],
                    timestamp: tmpTradeList[i].timestamp * 1000,
                    fromNetwork: network1,
                    toNetwork: network2,
                };
            }
        }
        return tradeList;
    };
    const addNetworkForTradeList = (tmpTradeList, network1, network2, networkCode, network1Id, network2Id) => {
        const tradeList = [];
        for (let i = 0; i < tmpTradeList.length; i++) {
            try {
                if (tmpTradeList[i].destCode != 1001) {
                    if (tmpTradeList[i].destCode != networkCode) {
                        const destNetworkInfo = networkManager.getNetworkInfoByCode(tmpTradeList[i].destCode, !isMainnet);
                        const destinationNetwork = NETWORKS.find((obj) => obj.networkID === destNetworkInfo.chainId);
                        tradeList[i] = {
                            ...tmpTradeList[i],
                            fromNetwork: network1,
                            toNetwork: destinationNetwork?.label,
                            explorerUrl: getExplorerUrl(tmpTradeList[i].newTradeTxHash, network1Id),
                            relayerExplorerUrl: getExplorerUrl(tmpTradeList[i].txhash, destinationNetwork.networkID.toString()),
                        };
                    }
                    else {
                        tradeList[i] = {
                            ...tmpTradeList[i],
                            fromNetwork: network2,
                            toNetwork: network1,
                            explorerUrl: getExplorerUrl(tmpTradeList[i].acceptedTxHash, network2Id),
                            relayerExplorerUrl: getExplorerUrl(tmpTradeList[i].acceptTxHash, network1Id),
                        };
                    }
                }
                else {
                    tradeList[i] = {
                        ...tmpTradeList[i],
                        fromNetwork: network1,
                        toNetwork: network2,
                        explorerUrl: getExplorerUrl(tmpTradeList[i].newTradeTxHash, network1Id),
                        relayerExplorerUrl: getExplorerUrl(tmpTradeList[i].txhash, network2Id),
                    };
                }
            }
            catch (error) {
                console.log(tmpTradeList[i].destCode);
                console.log(error);
            }
        }
        return tradeList;
    };
    const addNetworkForCctpTradeList = (tmpTradeList) => {
        const tradeList = [];
        for (let i = 0; i < tmpTradeList.length; i++) {
            try {
                const sourceNetwork = NETWORKS.find((obj) => obj.networkID === tmpTradeList[i].sourceNetworkId);
                const destinationNetwork = NETWORKS.find((obj) => obj.networkID === tmpTradeList[i].destinationNetworkId);
                tradeList[i] = {
                    ...tmpTradeList[i],
                    fromNetwork: sourceNetwork?.label,
                    toNetwork: destinationNetwork?.label,
                    explorerUrl: getExplorerUrl(tmpTradeList[i].burnTxHash, tmpTradeList[i].sourceNetworkId.toString()),
                    relayerExplorerUrl: getExplorerUrl(tmpTradeList[i].mintTxHash, tmpTradeList[i].destinationNetworkId.toString()),
                };
            }
            catch (error) {
                console.log(error);
            }
        }
        return tradeList;
    };
    const getExplorerUrl = (_hash, networkId) => {
        if (!_hash)
            return '';
        const r = Object.entries(blockExplorers).find(([key]) => key == networkId);
        if (r != null) {
            return r[1] + 'tx/' + _hash;
        }
        return '';
    };
    const getEvidence = async (_index) => {
        // const event = txEvents[_index]
        // const tx = await web3.getTransaction(event.transactionHash)
        // const parsedTxInput = child.parseTransaction(tx.input)
        // const txArgs = parsedTxInput.args
        // const tradeIndex = txArgs._userTrades.findIndex(
        //   (trade: any) =>
        //     trade.index.toString() == String(_index) &&
        //     trade.userAddress.toLowerCase() ==
        //       web3.getSelectedAddress().toLowerCase(),
        // )
        // if (tradeIndex != -1) {
        //   evidence[_index] = txArgs._evidences[tradeIndex]
        // }
    };
    const dispute = async (_index) => {
        // TODO
        // if (currentChain.id != 137 && currentChain.id != 80001) {
        //   if (!isMainnet) {
        //     switchNetwork?.(80001)
        //   } else {
        //     switchNetwork?.(137)
        //   }
        //   // TODO
        //   // await connectMetamask()
        // }
        // setIsProcessingModalOpen(true)
        // setIsProcessing(true)
        // child = await PheasantNetworkBridgeChild.init(
        //   web3,
        //   await getLatestContractAddress(
        //     currentChain.id,
        //     'pheasantNetworkBridgeAddress',
        //   ),
        //   process.env.REACT_APP_ENV,
        // )
        // const tx = child.dispute(web3.getSelectedAddress(), _index)
        // tx.then((result) => {
        //   setIsProcessingModalOpen(false)
        //   setIsNoticeModalOpen(true)
        //   setIsProcessing(false)
        //   setPhase(0)
        //   getTradeList()
        //   composeExplorerUrl(result.transactionHash)
        // }).catch(() => {
        //   setIsProcessingModalOpen(false)
        //   setIsProcessing(false)
        //   console.log(constants.METAMASK_GENERAL_ERROR)
        // })
    };
    const slash = async (_index) => {
        // const tx = child.slash(_index)
        // tx.then((result) => {
        //   getTradeList()
        //   composeExplorerUrl(result.transactionHash)
        // }).catch(() => {
        //   console.log(constants.METAMASK_GENERAL_ERROR)
        // })
    };
    return {
        tradeList,
        isFetchedTradeList,
    };
};
