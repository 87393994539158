// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bracket::before {
  content: '';
  width: 66.66%;
  height: 0.5rem;
  position: absolute;
  border: 1px solid #6b7280;
  border-top: none;
  top: 0;
  left: 33.33%;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

@media (max-width: 520px) {
  .history-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
  }
  .footer-buttons {
    flex-direction: column;
  }
  .footer-buttons button {
    width: 100%;
    margin: 0;
    margin-bottom: 3px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/domain/history/content.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,MAAM;EACN,YAAY;EACZ,kCAAkC;EAClC,iCAAiC;AACnC;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;EACd;EACA;IACE,sBAAsB;EACxB;EACA;IACE,WAAW;IACX,SAAS;IACT,kBAAkB;EACpB;AACF","sourcesContent":[".bracket::before {\n  content: '';\n  width: 66.66%;\n  height: 0.5rem;\n  position: absolute;\n  border: 1px solid #6b7280;\n  border-top: none;\n  top: 0;\n  left: 33.33%;\n  border-bottom-right-radius: 0.5rem;\n  border-bottom-left-radius: 0.5rem;\n}\n\n@media (max-width: 520px) {\n  .history-header {\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 8px;\n  }\n  .footer-buttons {\n    flex-direction: column;\n  }\n  .footer-buttons button {\n    width: 100%;\n    margin: 0;\n    margin-bottom: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
