import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import styled from 'styled-components';
const StyledCheckbox = styled.input `
  &:checked ~ .dot {
    transform: translateX(90%);
  }
`;
const Dot = styled.div `
  width: 5rem;
  height: 2rem;
  top: 0.125rem;
  left: 0.125rem;
  padding: 9px 21px;
  border-radius: 30px;
  border: 2px solid #00c9a2;
  gap: 4px;
`;
const NetworkToggleButton = memo(() => {
    const isMainnet = process.env.REACT_APP_IS_MAINNET === 'true';
    return (_jsx("label", { htmlFor: "toggle-testnet", className: "flex items-center cursor-pointer pr-4", tabIndex: 0, onKeyDown: (e) => {
            if (e.key === 'Enter') {
                const newTabUrl = !isMainnet
                    ? 'https://pheasant.network/'
                    : 'https://testnet.pheasant.network/';
                window.open(newTabUrl, '_blank');
            }
        }, children: _jsxs("div", { className: "relative", children: [_jsx(StyledCheckbox, { type: "checkbox", id: "toggle-testnet", className: "sr-only", onChange: () => {
                        const newTabUrl = !isMainnet
                            ? 'https://pheasant.network/'
                            : 'https://testnet.pheasant.network/';
                        window.open(newTabUrl, '_blank');
                    }, checked: !isMainnet, tabIndex: -1, "aria-checked": !isMainnet }), _jsxs("div", { className: "dark:bg-custom-light md:dark:bg-custom-lighter bg-gray-200 w-[9.438rem] h-9 rounded-full border dark:border-none flex justify-around items-center", children: [_jsx("span", { className: `font-medium text-xs left-3 bottom-2 ${isMainnet ? 'text-transparent' : 'text-gray-500'}`, children: "Mainnet" }), _jsx("span", { className: `font-medium text-xs left-3 bottom-2 ${!isMainnet ? 'text-transparent' : 'text-gray-500'}`, children: "Testnet" })] }), _jsx(Dot, { className: "dot absolute font-medium text-xs rounded-full flex justify-around items-center bg-white dark:bg-custom-dark", children: isMainnet ? 'Mainnet' : 'Testnet' })] }) }));
});
export default NetworkToggleButton;
