import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
const TimerManagerContext = createContext(undefined);
export const TimerManagerProvider = ({ children, }) => {
    const [timerIDs, setTimerIDs] = useState([]);
    const addTimerID = (id, type) => {
        setTimerIDs((prev) => [...prev, { id, type }]);
    };
    const removeTimerID = (id) => {
        setTimerIDs((prevIDs) => {
            const target = prevIDs.find((timer) => timer.id === id);
            if (target) {
                if (target.type === 'timeout') {
                    clearTimeout(id);
                }
                else if (target.type === 'interval') {
                    clearInterval(id);
                }
            }
            return prevIDs.filter((timer) => timer.id !== id);
        });
    };
    const clearAllTimerIDs = () => {
        timerIDs.forEach(({ id, type }) => {
            if (type === 'timeout') {
                clearTimeout(id);
            }
            else if (type === 'interval') {
                clearInterval(id);
            }
        });
        setTimerIDs([]);
    };
    return (_jsx(TimerManagerContext.Provider, { value: { timerIDs, addTimerID, removeTimerID, clearAllTimerIDs }, children: children }));
};
export const useTimerManagerContext = () => {
    const context = useContext(TimerManagerContext);
    if (!context) {
        throw new Error('useTimerManager must be used within a TimerManagerProvider');
    }
    return context;
};
