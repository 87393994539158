import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import '@/domain/history/content.css';
import { memo, useState } from 'react';
import { Transition } from 'react-transition-group';
import arrow from '@/assets/icon/line-triangle.svg';
import arrowWhite from '@/assets/icon/line-triangle-white.svg';
import { useIsDarkModeContext } from '@/context';
import Content from '@/domain/history/Content';
import { useHistory as useHistoryMainnet } from '@/hooks/mainnet/useHistory';
import { useHistory as useHistoryTestnet } from '@/hooks/testnet/useHistory';
const useHistory = process.env.REACT_APP_IS_MAINNET === 'true'
    ? useHistoryMainnet
    : useHistoryTestnet;
const HistoryCard = memo(({ cancelTrade }) => {
    const { isDarkMode } = useIsDarkModeContext();
    const { tradeList, isFetchedTradeList } = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const fadeStyles = {
        entering: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    };
    const slideStyles = {
        entered: { transition: 'all 0.3s ease', transform: 'translateY(0px)' },
        entering: { transform: 'translateY(1000px)' },
        exiting: {
            transition: 'all 0.15s ease',
            transform: 'translateY(1000px)',
        },
    };
    return (_jsxs("div", { className: "flex justify-center bg-transparent", children: [_jsx(Transition, { in: !isOpen, timeout: 1500, children: (state) => (_jsx("div", { style: fadeStyles[state], className: "history w-[600px] h-[100px] border text-sm border-dark dark:border-[#3A3A3A] rounded-2xl px-5 pt-4 pb-6 bg-ground dark:bg-[#262627] fixed overflow-hidden md:max-w-full md:w-full md:px-4 md:transform-none md:left-auto md:right-4 history-hide", children: _jsxs("div", { className: "flex flex-row justify-center items-center relative mb-4 mt-2", children: [_jsx("h2", { className: "text-xl font-medium", children: "History" }), _jsx("button", { onClick: () => setIsOpen(!isOpen), "aria-label": "toggle history", className: "absolute right-0 transition hover:-translate-y-1", children: _jsx("img", { src: isDarkMode ? arrowWhite : arrow, alt: "Arrow Icon", width: "20", height: "12", className: "w-5 transition rotate-0" }) })] }) })) }), _jsx(Transition, { in: isOpen, timeout: { appear: 0, exit: 500 }, unmountOnExit: true, children: (state) => (_jsxs("div", { style: slideStyles[state], className: "history w-[600px] h-[675px] max-h-screen border text-sm border-dark dark:border-[#3A3A3A] rounded-2xl px-5 pt-4 pb-6 bg-ground dark:bg-[#262627] fixed overflow-hidden md:max-w-full md:w-full md:px-4 md:transform-none md:left-auto md:right-4 history-show z-40", children: [_jsxs("div", { className: "flex flex-row justify-center items-center relative mb-4 mt-2", children: [_jsx("h2", { className: "text-xl font-medium", children: "History" }), _jsx("button", { onClick: () => setIsOpen(!isOpen), "aria-label": "toggle history", className: "absolute right-0 transition hover:translate-y-1", children: _jsx("img", { src: isDarkMode ? arrowWhite : arrow, alt: "Arrow Icon", width: "20", height: "12", className: "w-5 transition rotate-180" }) })] }), _jsx(Content, { tradeList: tradeList, cancelTrade: cancelTrade, isFetchedTradeList: isFetchedTradeList })] })) })] }));
});
export default HistoryCard;
